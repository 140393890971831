import { Injectable } from '@angular/core';
import { StorageDataService } from './storageData.service';
import { ProfileDataDto } from '../shared/dto/admin/profile.dto';

@Injectable({
  providedIn: 'root',
})
export class RoleHelper {
  constructor(private readonly storageDataService: StorageDataService) {}

  isAdmin() {
    const profile: ProfileDataDto = this.storageDataService.getProfileStorage();
    const filterRole = profile.roles.filter((e) => {
      return e.name === 'admin';
    });
    return filterRole.length > 0 ? true : false;
  }

  isSuperAdmin() {
    const profile: ProfileDataDto = this.storageDataService.getProfileStorage();
    const filterRole = profile.roles.filter((e) => {
      return e.name === 'super-admin';
    });
    return filterRole.length > 0 ? true : false;
  }

  isOwner() {
    const profile: ProfileDataDto = this.storageDataService.getProfileStorage();
    const filterRole = profile.roles.find((e) => {
      return e.name === 'agent';
    });
    if (filterRole) {
      return profile.agent.isOwner ? true : false;
    }
    return false;
  }

  isAgent() {
    const profile: ProfileDataDto = this.storageDataService.getProfileStorage();
    const filterRole = profile.roles.find((e) => {
      return e.name === 'agent';
    });

    if (filterRole) {
      return !profile.agent.isOwner ? true : false;
    }
    return false;
  }

  isInternalPrima() {
    const profile: ProfileDataDto = this.storageDataService.getProfileStorage();
    return profile.agent?.branch.isInternalPrima ? true : false;
  }
}
